import React, { Component } from "react";
import "./Quiz.css";
import Chat from "./Chat";

class Quiz extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="col-sm-3 col-md-12  stheader containerQuiz">
          <img src="/IMG/header-page-accueil-quiz.png" width="100%" />

          <div className="fond">
            <div>
              <p>
                Cliquez sur la salle correspondante à vottre session pour
                répondre aux questions
              </p>
            </div>
            <a
              href="https://synapsesquiz.com/quiz/#/pleniere"
              target="_blank"
              className="button"
            >
              {" "}
              Plénière
            </a>
            <a
              href="https://synapsesquiz.com/quiz/#/sepcognition"
              target="_blank"
              className="button"
            >
              {" "}
              Atelier "Sep et cognition"
            </a>
          </div>
        </div>
        <Chat />
      </div>
    );
  }
}

export default Quiz;
